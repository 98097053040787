
import React from 'react';
  
function LoadingScreen(props)
{
    return (
        <section id="LoadingScreen">
            <div className="loader"/>
        </section>
    );
};
  
export default LoadingScreen;
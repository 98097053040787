import React, { useState, useRef } from 'react';
import SESLogo from '../media/images/smart-electric-solutions-white-logo.png';

const menuItems = [
    { name: 'Residential Services',     href: '/residential-services',      section: 'Solutions' },
    { name: 'Commercial Services',      href: '/commercial-services',       section: 'Solutions' },
    { name: 'Electrical Maintenance',   href: '/electrical-maintenance',    section: 'Solutions' },
    { name: 'Power Systems',            href: '/power-systems',             section: 'Spaces' },
    { name: 'Lighting Solutions',       href: '/lighting-solutions',        section: 'Spaces' },
    { name: 'Energy Efficiency',        href: '/energy-efficiency',         section: 'Spaces' },
    { name: 'Smart Building Dashboard',     href: 'https://building-dashboard.vherngames.com', section: 'Spaces' },
    { name: 'Services',                 href: '/services' },
    { name: 'Safety Standards',         href: '/safety-standards' },
    { name: 'Gallery',                  href: '/gallery' },
    { name: 'Contact',                  href: '/#contactUs' }
];

const groupMenuItemsBySection = (items) =>
{
    return items.reduce((acc, item) =>
    {
        const section = item.section || 'Other Links';

        if (!acc[section]) acc[section] = [];
        acc[section].push(item);

        return acc;
    }, {});
};

function Navbar(props) 
{
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const groupedMenuItems = groupMenuItemsBySection(menuItems);

    document.documentElement.style.overflowY = isMobileMenuOpen ? 'hidden' : 'auto';

    const renderMobileMenuItems = () =>
    {
        return (
            <>
                {Object.keys(groupedMenuItems).map((section, index) => (
                    <div key={index} className="mobile-menu-section mt-4">
                        <h4 className="mobile-menu-header small text-white-50 mb-2">{section}</h4>
                        <ul className="navbar-nav">
                            {groupedMenuItems[section].map((item, idx) => (
                                <li key={idx} className="nav-item">
                                    <a className="nav-link" href={item.href}>
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </>
        );
    };

    const renderDesktopDropdown = (section) =>
    {
        return (
            <li className="nav-item dropdown" key={section}>
                <a
                    className="nav-link animated-link"
                    href="#"
                    id={`${section}DropdownDesktop`}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false" >
                    {section} &#9662;
                </a>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby={`${section}DropdownDesktop`}>
                    {groupedMenuItems[section].map((item, index) => (
                        <li key={index}>
                            <a className="dropdown-item animated-link" href={item.href}>
                                {item.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </li>
        );
    };

    return (
        <>
            {/* Mobile Navbar */}
            <nav className="navbar navbar-expand-lg navbar-dark d-lg-none py-4">
                <div className="container-fluid">
                    <button
                        ref={buttonRef}
                        className={`navbar-toggler custom-toggler ${isMobileMenuOpen ? 'open' : 'collapsed'}`}
                        type="button"
                        onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                        aria-label="Toggle navigation" >
                        <span className="toggler-icon-bar bar1"></span>
                        <span className="toggler-icon-bar bar2"></span>
                        <span className="toggler-icon-bar bar3"></span>
                    </button>

                    <a className="navbar-brand ms-auto" href="/" style={{ zIndex: "10000" }}>
                        <img alt="SES Logo" height="80rem" src={SESLogo} />
                    </a>
                </div>

                {/* Mobile Menu */}
                <div
                    ref={menuRef}
                    className={`mobile-menu d-flex justify-content-center align-items-center ${isMobileMenuOpen ? 'menu-open' : ''}`} >
                    <ul className="navbar-nav">
                        {renderMobileMenuItems()}
                    </ul>
                </div>
            </nav>

            {/* Desktop Navbar */}
            <nav className="navbar navbar-expand-lg navbar-dark d-none d-lg-block py-4">
                <div className="container-fluid">
                    <ul className="navbar-nav d-flex flex-row align-items-center mx-auto" style={{ gap: '10px' }}>
                        {Object.keys(groupedMenuItems).filter(section => section !== 'Other Links').map((section, index) => (
                            renderDesktopDropdown(section)
                        ))}

                        {/* Left side items */}
                        <li className="nav-item">
                            <a className="nav-link animated-link" href={groupedMenuItems['Other Links'][0].href}>
                                {groupedMenuItems['Other Links'][0].name}
                            </a>
                        </li>

                        <li className="nav-item">
                            <a className="navbar-brand mx-3" href="/">
                                <img alt="SES Logo" height="80rem" src={SESLogo} />
                            </a>
                        </li>

                        {/* Remaining 'Other Links' */}
                        {groupedMenuItems['Other Links'].slice(1).map((item, index) => (
                            <li key={index} className="nav-item">
                                <a className="nav-link animated-link" href={item.href}>
                                    {item.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;

import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import backgroundVideo from '../media/videos/backgroundVideo.mp4';
import Navbar from '../components/Navbar';
import { ReactComponent as Phone } from "../media/images/svg/phone.svg";

const frameRate = 30;
const segments =
[
    { startFrame: 0, endFrame: 297, position: "75% 50%" }, // First segment
    { startFrame: 298, endFrame: 673, position: "50% 50%" }, // Second segment
    { startFrame: 674, endFrame: 1018, position: "70% 50%" }, // Third segment
    { startFrame: 1019, endFrame: 1318, position: "30% 50%" }, // Fourth segment
    { startFrame: 1319, endFrame: 1656, position: "30% 50%" }  // Fifth segment
];

function MainScreen(props)
{
    const inHomePage = useLocation().pathname === "/";
    const videoRef = useRef(null);

    useEffect(() =>
    {
        const video = videoRef.current;
        if (!video || !inHomePage)
            return;

        const updatePosition = () =>
        {
            const currentTime = video.currentTime;
            const currentFrame = Math.round(currentTime * frameRate);

            const currentSegment = segments.find(
                (segment) =>
                    currentFrame >= segment.startFrame && currentFrame <= segment.endFrame
            );

            if (currentSegment)
            {
                video.style.objectPosition = currentSegment.position;
                video.getBoundingClientRect();
            }

            requestAnimationFrame(updatePosition);
        };

        requestAnimationFrame(updatePosition);

        return () => cancelAnimationFrame(updatePosition);
    }, [inHomePage]);
     
    return (
        <div>
            { inHomePage ?
            <section id="mainScreen" className="container-fluid p-0">
                <div className="d-flex flex-column h-100">
                    <div className="row g-0">
                        <div id="callToday" className="d-flex justify-content-center col-12 p-2 small">
                            <a>Call Today:&nbsp;<a href="tel:3052056409">(305) 205-6409</a></a>
                        </div>
                        <Navbar/>
                            <div id="calculateBudget" className="d-flex justify-content-center col-12 p-3">
                                <a className='animated-link' href="#contactUs" style={{ textDecoration: 'none' }} >
                                    Schedule a Free Consultation
                                </a>
                                <style> {` #calculateBudget a { color: black !important; } `} </style>
                            </div>
                        </div>

                        <video
                            ref={videoRef}
                            autoPlay
                            loop
                            muted
                            playsInline
                            id="mainScreenVideo"
                            controls={false}>
                            <source src={backgroundVideo} type="video/mp4" />
                        </video>

                    <div id="mainScreenWindow" className='d-none d-md-block'>
                        <p className="pb-5"><a href="tel:3052056409"><Phone className='accentColor'/> (305) 205-6409</a></p>
                        <h1 className='display-5'>Electrical Solutions for Home & Business</h1>
                        <p className='py-3'>From installations to maintenance, we provide safe and efficient electrical services to power your future.</p>
                        <a className="whiteLink animated-link" href="#CommitmentToCraftmanship"> LEARN MORE</a>
                    </div>
                </div>
            </section>
            : <Navbar/> }
        </div>
    );
};

export default MainScreen;

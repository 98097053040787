import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import './css/main.css'
import './css/animations.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Loading from "./pages/loading.js";

import MainScreen from "./components/MainScreen.js"
import Footer from "./components/Footer.js"

const Home = lazy(() => import("./pages/home.js"));

const ResidentialServices = lazy(() => import("./pages/residential-services.js"));
const CommercialServices = lazy(() => import("./pages/commercial-services.js"));
const ElectricalMaintenance = lazy(() => import("./pages/electrical-maintenance.js"));
const PowerSystems = lazy(() => import("./pages/power-systems.js"));
const LightingSolutions = lazy(() => import("./pages/lighting-solutions.js"));
const EnergyEfficiency = lazy(() => import("./pages/energy-efficiency.js"));
const Services = lazy(() => import("./pages/services.js"));
const SafetyStandards = lazy(() => import("./pages/safety-standards.js"));
const Gallery = lazy(() => import("./pages/gallery.js"));

//const PrivacyPolicy = lazy(() => import("./pages/privacy-policy.js"));
//const CookiePolicy = lazy(() => import("./pages/cookie-policy.js"));
//const TermsAndConditions = lazy(() => import("./pages/terms-and-conditions.js"));

const NotFound = lazy(() => import("./pages/404.js"));

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Suspense fallback={<Loading/>}>
          <MainScreen/>
          <Routes>
              <Route exact path='/' element={<Home/>} />
              <Route exact path='/residential-services' element={<ResidentialServices/>} />
              <Route exact path='/commercial-services' element={<CommercialServices/>} />
              <Route exact path='/electrical-maintenance' element={<ElectricalMaintenance/>} />
              <Route exact path='/power-systems' element={<PowerSystems/>} />
              <Route exact path='/lighting-solutions' element={<LightingSolutions/>} />
              <Route exact path='/energy-efficiency' element={<EnergyEfficiency/>} />
              <Route exact path='/services' element={<Services/>} />
              <Route exact path='/safety-standards' element={<SafetyStandards/>} />
              <Route exact path='/gallery' element={<Gallery/>} />
              <Route path='*' element={<NotFound/>} />
          </Routes>
          <Footer/>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
